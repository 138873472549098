import { UseQueryOptions } from "@tanstack/react-query"
import { useCallback, useMemo } from "react"
import { DateTime } from "luxon"
import { createSelector } from "reselect"
import { useGeoDataQuery } from "../_gen/hooks"
import { GeoDataQuery } from "../_gen/operations"
import { GraphQLError } from "../../lib/graphql/errors"
import {
  TimeZoneContext,
  useTimeZone,
} from "../../app/[locale]/(boundary)/_providers/TimeZoneProvider"
import { TimeZone } from "../_gen/types"
import { GeoData } from "./geodata"

// Transforming the geodata is very expensive and runs all over the app hundreds of times,
// so it's important the transform only runs once when the data changes, rather than once
// per component instance. For this reason we compute once and memoize the result globally.
// See: https://github.com/TanStack/query/discussions/5094
const computedData = createSelector(
  [
    (zones: TimeZoneContext) => zones.effective,
    (zones: TimeZoneContext) => zones.system,
    (zones: TimeZoneContext) => zones.override,
    (_: TimeZoneContext, data: GeoDataQuery) => data,
  ],
  (effective, system, override, data) => {
    const effectiveRawZone = data.timeZones?.edges.find(
      (edge) => edge.node.id === effective,
    )?.node

    if (!effectiveRawZone) {
      throw new Error("Effective time zone not found")
    }

    const countries =
      data?.countries?.edges.reduce((acc, country) => {
        acc[country.node.code] = country.node
        return acc
      }, {} as GeoData["countries"]) || {}

    const timeZones =
      data?.timeZones?.edges.reduce((acc, timeZone) => {
        acc[timeZone.node.id] = timeZone.node /*{
          Commented out for performance reasons for now
          ...timeZone.node,
          deltaDescription: describeFn(timeZone.node, effectiveRawZone),
        }*/
        return acc
      }, {} as GeoData["timeZones"]) || {}

    const timeZonesByRegion =
      Object.values(timeZones).reduce((acc, timeZone) => {
        const region = timeZone.region
        acc[region] = acc[region] || []
        acc[region].push(timeZone)
        return acc
      }, {} as GeoData["timeZonesByRegion"]) || {}

    const effectiveZone = timeZones[effective]
    const systemZone = timeZones[system]
    const overrideZone = override ? timeZones[override] : undefined

    return {
      countries,
      timeZones,
      timeZonesByRegion,
      effectiveZone,
      systemZone,
      overrideZone,
    }
  },
)

export const useGeoData = (
  options?: UseQueryOptions<GeoDataQuery, string | GraphQLError, GeoData>,
) => {
  const timeZones = useTimeZone()

  const select = useCallback(
    (data: GeoDataQuery) => computedData(timeZones, data),
    [timeZones],
  )

  const opts = useMemo(
    () => ({
      select,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      staleTime: 60 * 60 * 1000,
      ...options,
    }),
    [select, options],
  )

  return useGeoDataQuery(undefined, opts)
}

export const timeZoneDelta = (zone: TimeZone, effectiveZoneId: string) => {
  const systemOffset = DateTime.utc().setZone(effectiveZoneId).offset
  const cityOffset = DateTime.utc().setZone(zone.id).offset
  return cityOffset - systemOffset
}

/*export const useTimeZoneDescription = (): ((
  zone: TimeZone,
  userZone: TimeZone,
) => string | undefined) => {
  const t = useTranslations("time_zones")
  const durationFormatter = useDurationFormatter()
  return useCallback(
    (zone: TimeZone, userZone: TimeZone) => {
      const delta = timeZoneDelta(zone, userZone.id)

      if (zone.id === userZone.id) {
        return
      }

      if (delta === 0) {
        return t("same_time_as_city", { city: userZone.city })
      }

      const hours = Math.floor(Math.abs(delta) / 60)
      const minutes = Math.abs(delta) % 60

      const formattedDuration = durationFormatter(
        Duration.fromObject({ hours, minutes }),
        true,
      )

      return delta > 0
        ? t("duration_ahead_of_city", {
            city: userZone.city,
            duration: formattedDuration,
          })
        : t("duration_behind_city", {
            city: userZone.city,
            duration: formattedDuration,
          })
    },
    [durationFormatter, t],
  )
}*/
