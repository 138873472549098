import { UseQueryOptions } from "@tanstack/react-query"
import { useCallback } from "react"
import { UpdateOperation, useOptimisticBuffer } from "@daybridge/optimism"
import { useAccountQuery } from "../_gen/hooks"
import {
  AccountQuery,
  UpdateAccountMutationVariables,
} from "../_gen/operations"
import { GraphQLError } from "../../lib/graphql/errors"
import { useTimeZone } from "../../app/[locale]/(boundary)/_providers/TimeZoneProvider"
import { accountFromGraphQL } from "./accountFromGraphQL"
import { Account } from "./account"

const applyPatch = (
  account: Account,
  patch: UpdateAccountMutationVariables,
): Account => {
  return {
    ...account,
    ...patch,
  }
}

const applyPatches = (
  account: Account,
  patches: UpdateOperation<UpdateAccountMutationVariables>[],
): Account => {
  return patches.reduce((acc, patch) => applyPatch(acc, patch.payload), account)
}

export const useAccount = (
  options?: UseQueryOptions<AccountQuery, string | GraphQLError, Account>,
) => {
  const timeZone = useTimeZone()

  const optimisticOperations = useOptimisticBuffer<
    unknown,
    UpdateAccountMutationVariables
  >("Account")

  const select = useCallback(
    (data: AccountQuery): Account => {
      if (!data.currentAccount) throw new Error("No current user")
      const account = accountFromGraphQL(
        data.currentAccount,
        timeZone.effective,
      )
      return applyPatches(
        account,
        optimisticOperations.operations.filter(
          (op): op is UpdateOperation<UpdateAccountMutationVariables> =>
            op.type === "update",
        ),
      )
    },
    [timeZone.effective, optimisticOperations.operations],
  )

  return useAccountQuery<Account, string | GraphQLError>(undefined, {
    refetchOnMount: false,
    ...options,
    select,
  })
}
