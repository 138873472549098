import { DateTime } from "luxon"
import { DayOfWeek } from "../_gen/types"
import { AccountQuery } from "../_gen/operations"
import { Account } from "./account"

export const accountFromGraphQL = (
  data: NonNullable<AccountQuery["currentAccount"]>,
  timeZone: string,
): Account => {
  return {
    id: data.id,
    name: data.name ?? "",
    username: data.username ?? "",
    userNumber: data.userNumber ?? undefined,
    avatarUrl: data.avatar?.url ?? undefined,
    profileColor: data.profileColor?.hue ?? 0,
    timeZone: data.timeZone?.id ?? undefined,
    country: data.country ?? undefined,
    locale: data.locale ?? undefined,
    eventAlerts: data.defaultEventAlerts,
    allDayEventAlerts: data.defaultAllDayEventAlerts,
    onboardingCompleted: data.onboardingCompleted ?? false,
    onboardingCompletedAt: data.onboardingCompletedAt
      ? DateTime.fromISO(data.onboardingCompletedAt ?? "").setZone(timeZone)
      : undefined,
    migrated: data.migrated ?? false,
    optInToUpdates: data.optInToUpdates ?? false,
    weekStart: data.weekStart ?? DayOfWeek.Monday,
    joinedAt: data.joinedAt
      ? DateTime.fromISO(data.joinedAt ?? "").setZone(timeZone)
      : DateTime.now().setZone(timeZone),
    phoneNumbers: data.phoneNumbers.edges.map((e) => e.node) ?? [],
    emailAddresses: data.emailAddresses.edges.map((e) => e.node) ?? [],
  }
}
