import { useOptimisticMutations, useOptimisticQuery } from "@daybridge/optimism"
import { InfiniteData, UseInfiniteQueryOptions } from "@tanstack/react-query"
import { GraphQLError } from "graphql-request/dist/types"
import useFetcher from "../../lib/graphql/fetcher"
import {
  TagsDocument,
  CreateTagDocument,
  DeleteTagDocument,
  UpdateTagDocument,
  ReorderTagsDocument,
} from "../_gen/hooks"
import {
  TagsQueryVariables,
  TagsQuery,
  DeleteTagMutationVariables,
  UpdateTagMutationVariables,
  DeleteTagMutation,
  UpdateTagMutation,
  CreateTagMutation,
  CreateTagMutationVariables,
  ReorderTagsMutation,
  ReorderTagsMutationVariables,
} from "../_gen/operations"
import { IconId } from "../_gen/types"
import { Tag } from "./Tag"

export const tagFromGraphQL = (
  tag: NonNullable<TagsQuery["tags"]>["edges"][number]["node"],
): Tag => ({
  id: tag.id,
  name: tag.name || "",
  hue: tag.color?.hue || 0,
  icon: tag.icon?.id || IconId.Tag,
})

const marshalTags = (data: InfiniteData<TagsQuery>): Tag[] =>
  data.pages.flatMap(
    (page) =>
      page.tags?.edges.map((edge) => {
        return tagFromGraphQL(edge.node)
      }) || [],
  ) || []

const createTemporaryTag = (variables: CreateTagMutationVariables): Tag => ({
  id: variables.input.idempotencyKey,
  name: variables.input.create.name,
  hue: variables.input.create.color?.hue || 0,
  icon: variables.input.create.icon?.id || IconId.Tag,
  pending: true,
})

const applyTagPatch = (tag: Tag, patch: UpdateTagMutationVariables) => {
  const {
    patch: { name, color, icon },
  } = patch.input
  return {
    ...tag,
    name: name || tag.name,
    hue: color?.hue || tag.hue,
    icon: icon?.id || tag.icon,
  }
}

export const useTags = (
  variables?: TagsQueryVariables,
  options?: UseInfiniteQueryOptions<TagsQuery, GraphQLError, Tag[]>,
) =>
  useOptimisticQuery({
    queryName: "Tags",
    variables,
    fetcher: useFetcher<TagsQuery, TagsQueryVariables>(TagsDocument).bind(
      null,
      variables,
    ),
    fromNetworkObject: marshalTags,
    options,
    getPageInfo: (data) => data.tags?.pageInfo,
    createTemporaryDomainObject: createTemporaryTag,
    applyPatch: applyTagPatch,
  })

export const useTagOperations = () =>
  useOptimisticMutations({
    queryName: "Tags",
    additionalInvalidationKeys: [
      ["Items"],
      ["CalendarAccounts"],
      ["OAuthConnections"],
    ],
    create: {
      fetcher: useFetcher<CreateTagMutation, CreateTagMutationVariables>(
        CreateTagDocument,
      ),
    },
    update: {
      fetcher: useFetcher<UpdateTagMutation, UpdateTagMutationVariables>(
        UpdateTagDocument,
      ),
    },
    delete: {
      fetcher: useFetcher<DeleteTagMutation, DeleteTagMutationVariables>(
        DeleteTagDocument,
      ),
    },
    reorder: {
      fetcher: useFetcher<ReorderTagsMutation, ReorderTagsMutationVariables>(
        ReorderTagsDocument,
      ),
    },
  })
