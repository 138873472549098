import { DateTime } from "luxon"
import { User as GraphQLUser } from "../_gen/types"
import { User } from "./User"

export const userFromGraphQL = (user: GraphQLUser, timeZone: string): User => {
  return {
    type: "user",
    id: user.id,
    name: user.name ?? undefined,
    username: user.username ?? undefined,
    avatarUrl: user.avatar?.url ?? undefined,
    country: user.country ?? undefined,
    timeZone: user.timeZone?.id ?? undefined,
    joinedAt: user.joinedAt
      ? DateTime.fromISO(user.joinedAt ?? "").setZone(timeZone)
      : DateTime.now().setZone(timeZone),
    userNumber: user.userNumber ?? 0,
    profileColor: user.profileColor?.hue ?? 0,
  }
}
