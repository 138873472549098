import { DateTime } from "luxon"
import { CalendarAccountsQuery } from "../_gen/operations"
import {
  CalendarParticipant as GraphQLCalendarParticipant,
  OAuthProviderId,
} from "../_gen/types"
import { areaFromGraphQL } from "../areas/useAreas"
import { tagFromGraphQL } from "../tags/useTags"
import { userFromGraphQL } from "../users/userFromGraphQL"
import { Calendar, CalendarAccount, CalendarParticipant } from "./Calendar"

export const calendarAccountFromGraphQL = (
  provider: NonNullable<
    CalendarAccountsQuery["calendarAccounts"]
  >["edges"][0]["node"],
  calendars: Calendar[],
): CalendarAccount => {
  switch (provider.__typename) {
    case "Account":
      return {
        id: provider.id,
        providerId: OAuthProviderId.Daybridge,
        icon: "Daybridge",
        providerName: "Daybridge",
        username: provider.username || "",
        calendars,
      }
    case "GoogleOAuthConnection":
      return {
        id: provider.id,
        providerId: OAuthProviderId.Google,
        accountId: provider.accountId,
        username: provider.email || "",
        customName: provider.customName || undefined,
        icon: "Google",
        providerName: "Google",
        defaultArea: provider.defaultArea
          ? areaFromGraphQL(provider.defaultArea)
          : undefined,
        status: provider.status,
        calendars,
      }
  }
}

export function calendarFromGraphQL(
  graphQLCalendar: NonNullable<
    CalendarAccountsQuery["calendarAccounts"]
  >["edges"][0]["node"]["calendars"]["edges"][0]["node"],
  timeZone: string,
): Calendar {
  const targetCalendar: Calendar = {
    id: graphQLCalendar.id,
    name: graphQLCalendar.name || "",
    customName: graphQLCalendar.customName ?? undefined,

    account:
      graphQLCalendar.account &&
      (graphQLCalendar.account.providerId === OAuthProviderId.Daybridge ||
        graphQLCalendar.account.providerId === OAuthProviderId.Google)
        ? {
            id: graphQLCalendar.account.id,
            providerId: graphQLCalendar.account.providerId,
            defaultArea:
              graphQLCalendar.account.__typename === "GoogleOAuthConnection" &&
              graphQLCalendar.account.defaultArea
                ? areaFromGraphQL(graphQLCalendar.account.defaultArea)
                : undefined,
          }
        : undefined,

    participants:
      graphQLCalendar.participants?.edges.map((edge) =>
        calendarParticipantFromGraphQL(edge.node, timeZone),
      ) || [],

    timeZone: graphQLCalendar.timeZone ?? undefined,
    defaultArea: graphQLCalendar.defaultArea
      ? areaFromGraphQL(graphQLCalendar.defaultArea)
      : undefined,
    defaultTags:
      graphQLCalendar.defaultTags?.edges.map((edge) =>
        tagFromGraphQL(edge.node),
      ) || [],

    defaultEventAlerts: graphQLCalendar.defaultEventAlerts ?? null,
    defaultAllDayEventAlerts: graphQLCalendar.defaultAllDayEventAlerts ?? null,

    enabled:
      graphQLCalendar.__typename === "DaybridgeCalendar"
        ? true
        : graphQLCalendar.enabled,

    createdAt: graphQLCalendar.createdAt
      ? DateTime.fromISO(graphQLCalendar.createdAt).setZone(timeZone)
      : undefined,
    updatedAt: graphQLCalendar.updatedAt
      ? DateTime.fromISO(graphQLCalendar.updatedAt).setZone(timeZone)
      : undefined,
  }
  return targetCalendar
}

export const calendarParticipantFromGraphQL = (
  participant: GraphQLCalendarParticipant,
  timeZone: string,
): CalendarParticipant => {
  const sharedFields = {
    id: participant.id,
    role: participant.role,
    status: participant.status,
    statusUpdatedAt: participant.statusUpdatedAt
      ? DateTime.fromISO(participant.statusUpdatedAt).setZone(timeZone)
      : undefined,
    invitedBy: participant.invitedBy
      ? userFromGraphQL(participant.invitedBy, timeZone)
      : undefined,
    invitedAt: participant.invitedAt
      ? DateTime.fromISO(participant.invitedAt).setZone(timeZone)
      : undefined,
  }

  if (participant.user) {
    return {
      ...sharedFields,
      type: "user",
      user: userFromGraphQL(participant.user, timeZone),
    }
  }
  if (participant.emailAddress) {
    return {
      ...sharedFields,
      type: "email",
      emailAddress: participant.emailAddress,
    }
  }

  throw new Error("Invalid participant")
}
