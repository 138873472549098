import { useOptimisticMutations, useOptimisticQuery } from "@daybridge/optimism"
import { InfiniteData, UseInfiniteQueryOptions } from "@tanstack/react-query"
import { GraphQLError } from "graphql-request/dist/types"
import useFetcher from "../../lib/graphql/fetcher"
import {
  AreasDocument,
  CreateAreaDocument,
  DeleteAreaDocument,
  ReorderAreasDocument,
  UpdateAreaDocument,
} from "../_gen/hooks"
import {
  AreasQueryVariables,
  AreasQuery,
  DeleteAreaMutationVariables,
  UpdateAreaMutationVariables,
  DeleteAreaMutation,
  UpdateAreaMutation,
  CreateAreaMutation,
  CreateAreaMutationVariables,
  ReorderAreasMutation,
  ReorderAreasMutationVariables,
} from "../_gen/operations"
import { IconId } from "../_gen/types"
import { Area } from "./Area"

export const areaFromGraphQL = (
  area: NonNullable<AreasQuery["areas"]>["edges"][number]["node"],
): Area => ({
  id: area.id,
  name: area.name || "",
  hue: area.color?.hue || 0,
  icon: area.icon?.id ? area.icon.id : IconId.Area,
})

const marshalAreas = (data: InfiniteData<AreasQuery>): Area[] =>
  data.pages.flatMap(
    (page) =>
      page.areas?.edges.map((edge) => {
        return areaFromGraphQL(edge.node)
      }) || [],
  ) || []

const createTemporaryArea = (variables: CreateAreaMutationVariables): Area => ({
  id: variables.input.idempotencyKey,
  name: variables.input.create.name,
  hue: variables.input.create.color?.hue || 0,
  icon: variables.input.create.icon?.id || IconId.Area,
  pending: true,
})

const applyAreaPatch = (area: Area, patch: UpdateAreaMutationVariables) => {
  const {
    patch: { name, color, icon },
  } = patch.input

  return {
    ...area,
    name: name || area.name,
    hue: color?.hue || area.hue,
    icon: icon?.id || area.icon,
  }
}

const getPageInfo = (data: AreasQuery) => data.areas?.pageInfo

export const useAreas = (
  variables?: AreasQueryVariables,
  options?: UseInfiniteQueryOptions<AreasQuery, GraphQLError, Area[]>,
) =>
  useOptimisticQuery({
    queryName: "Areas",
    variables,
    fetcher: useFetcher<AreasQuery, AreasQueryVariables>(AreasDocument).bind(
      null,
      variables,
    ),
    fromNetworkObject: marshalAreas,
    options,
    getPageInfo,
    createTemporaryDomainObject: createTemporaryArea,
    applyPatch: applyAreaPatch,
  })

export const useAreaOperations = () =>
  useOptimisticMutations({
    queryName: "Areas",
    additionalInvalidationKeys: [
      ["Items"],
      ["CalendarAccounts"],
      ["OAuthConnections"],
    ],
    create: {
      fetcher: useFetcher<CreateAreaMutation, CreateAreaMutationVariables>(
        CreateAreaDocument,
      ),
    },
    update: {
      fetcher: useFetcher<UpdateAreaMutation, UpdateAreaMutationVariables>(
        UpdateAreaDocument,
      ),
    },
    delete: {
      fetcher: useFetcher<DeleteAreaMutation, DeleteAreaMutationVariables>(
        DeleteAreaDocument,
      ),
    },
    reorder: {
      fetcher: useFetcher<ReorderAreasMutation, ReorderAreasMutationVariables>(
        ReorderAreasDocument,
      ),
    },
  })
